<template>
  <el-card style="min-height: 45rem">
    <el-tabs>
      <el-tab-pane label="基础信息">
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form label-position="top">
              <el-form-item label="课程名称">
                <el-input v-model="form.title"></el-input>
              </el-form-item>
              <el-form-item label="课程缩略图">
                <y_upload_img v-model="form.pic"></y_upload_img>
              </el-form-item>
              <el-form-item label="详情富文本">
                <y_editor v-model="form.detail"></y_editor>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="12">
            <el-form>
              <el-form-item label="选择分类">
                <y_choose_cate v-model="form.cate_id"></y_choose_cate>
              </el-form-item>
              <el-form-item label="选择讲师">
                <y_choose_teacher v-model="form.teacher_id"></y_choose_teacher>
              </el-form-item>
              <el-form-item label="报名金额">
                <el-input-number v-model="form.fee"></el-input-number>元
              </el-form-item>
              <el-form-item label="讲师佣金">
                <el-input-number v-model="form.award_fee"></el-input-number>元
              </el-form-item>
              <el-form-item label="免费等级">
                <el-select @change="scopeChange" style="width: 100%" v-model="scope_show" multiple placeholder="请选择">
                  <el-option v-for="item in scope_option" :key="item.level" :label="item.title" :value="item.level"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button @click="edit" type="primary">提交</el-button>
                <el-button @click="$router.back()">返回列表</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="详情轮播图">
        <el-table :data="showBannerList">
          <el-table-column label="图片">
            <template #default="s">
              <img :src="s.row | tomedia" style="width: 2rem;height: 2rem" alt="">
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template #header>
              <el-button @click="bannerAdd">添加图片</el-button>
              <el-button @click="edit" type="primary">保存提交</el-button>
              <y_upload_img :show-input="false" ref="upload"></y_upload_img>
            </template>
            <template #default="s">
              <el-button @click="bannerRemove(s.$index)">移除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>

<script>
import y_editor from "@/components/y-form/y-editor/y_editor";
import y_upload_img from "@/components/y-form/y-upload/y_upload_img";
import {levelOption} from "@/common/mod/user";
import y_choose_cate from "@/components/shop/plugin/knowledge/y_choose_cate";
import y_choose_teacher from "@/components/shop/plugin/knowledge/y_choose_teacher";

export default {
  components:{
    y_editor,
    y_upload_img,
    y_choose_cate,
    y_choose_teacher,
  },
  name: "edit",
  data(){
    return{
      form:{
        teacher_id:0,
        cate_id:0,
        title:"",
        pic:"",
        detail:"",
        fee:0,
        award_fee:0,
        free_level:"",
        banner:"",
      },
      edit_id:0,
      scope_show:[],
      level_row:levelOption,
      showBannerList:[],
    }
  },
  computed:{
    scope_option(){
      return this.level_row;
    }
  },
  mounted() {
    if(this.$route.query.id){
      this.edit_id = parseInt(this.$route.query.id);
      this.load();
    }
  },
  methods:{
    scopeChange(val){
      this.form.free_level = val.join(",");
    },
    load(){
      this.$u.api.shop.plugin.knowledge.courseOne({id:this.edit_id}).then(res=>{
        this.form = res;
        if(this.form.banner) this.showBannerList = JSON.parse(this.form.banner);
        if(this.form.free_level){
          let strArr = this.form.free_level.split(",");
          strArr.forEach((v,k)=>{
            strArr[k] = parseInt(v)
          })
          this.scope_show = strArr;
        }
      })
    },
    edit(){
      this.form.banner = JSON.stringify(this.showBannerList);
      this.$u.api.shop.plugin.knowledge.courseEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.$router.back();
      })
    },
    bannerRemove(index){
      this.showBannerList.splice(index,1)
    },
    bannerAdd(){
      this.$refs.upload.showUpload(e=>{
        this.showBannerList.push(e)
      })
    },
  },
}
</script>

<style scoped>

</style>