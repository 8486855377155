<template>
  <el-select v-model="value" :loading="loading">
    <el-option v-for="item in options" :key="item.value" :label="item.title" :value="item.id"></el-option>
  </el-select>
</template>

<script>
export default {
  name: "y_choose_teacher",
  props:{
    modelVal: {
      type:Number,
      default:0
    }
  },
  model:{
    prop:"modelVal",
    event:"change"
  },
  watch:{
    value(e){
      this.$emit("change",e)
    },
    modelVal(e){
      if(this.modelVal > 0){
        this.value = e;
      }
    }
  },
  data(){
    return{
      value:"",
      options:[],
      loading: false,
    }
  },
  mounted() {
    this.loadOpt();
  },
  methods:{
    loadOpt(){
      this.$u.api.shop.plugin.knowledge.teacher({page:-1}).then(res=>{
        this.options = res.list;
      })
    }
  }
}
</script>

<style scoped>

</style>